import Papa from 'papaparse';

import { CustomerRole } from '../../auth/AuthProvider';
import { AssetType } from '../../models/AssetType';

const getMatchingCustomerNbr = (customerRole: CustomerRole): string => {
  switch (customerRole) {
    case CustomerRole.cadenceUser:
      return '33235';
    case CustomerRole.valleyNationalBankUser:
      return '28137';
    case CustomerRole.gecuUser:
      return '6466970';
    default:
      return '000000';
  }
};

const parseCSV = async (customerRole: CustomerRole): Promise<AssetType[]> => {
  try {
    const response = await fetch('/customer_asset_list.csv');
    const csvText = await response.text();
    return new Promise((resolve, reject) => {
      Papa.parse(csvText, {
        header: true,
        complete: (result) => {
          const customerAssets: AssetType[] = result.data
            .filter((asset: any) =>
              customerRole === 'ncr-customer'
                ? !!asset.customerNbr
                : asset.customerNbr === getMatchingCustomerNbr(customerRole)
            )
            .map((asset: any) => {
              const service = {
                id: asset.serviceTag,
                serviceNbr: `12345`,
                serviceCategory: `service-cat-1`,
                serviceDesc: `example-service`,
                createdBy: `User-${Math.floor(1 + Math.random() * 10)}`,
                createdAt: new Date(
                  Date.now() - 1000 * 60 * 60 * 24 * 30 * 1
                ).toLocaleDateString('en-US'),
                updatedAt: new Date().toISOString(),
                active: true,
                serviceStatus: `ACTIVE`,
                autoUpdate: true,
                added: true,
                removed: false,
              };

              const site = {
                locationMismatched: false,
                id: asset.siteId,
                ncrSiteId: `id-123`,
                customerId: `customer-123`,
                name: asset.siteName,
                originalCorp: `org-corp`,
                branchNo: `branch`,
                siteStatus: `ACTIVE`,
                sitePulse: false,
                siteDuns: ``,
                addressLine1: asset.address,
                addressLine2: null,
                city: asset.city,
                state: asset.state,
                province: `Province`,
                postalCode: `PC`,
                country: asset.country,
                geotag: [
                  `geo:${(Math.random() * 90).toFixed(6)},${(
                    Math.random() * 180
                  ).toFixed(6)}`,
                ],
                serviceLocationCode: `12345`,
                siteUsage: ``,
                channelOrgCode: ``,
                sitePrefLang: ``,
                createdAt: new Date(
                  Date.now() - 1000 * 60 * 60 * 24 * 30 * 1
                ).toLocaleDateString('en-US'),
                updatedAt: new Date().toISOString(),
                lastScannedTimeStamp: new Date().toISOString(),
              };

              const customer = {
                autoUpdatedAssetCount: 15,
                requireContractCount: 8,
                updatedAssetCount: 20,
                serviceMismatchAssetCount: 3,
                actionRequiredAssetCount: 5,
                autoMovedAssetCount: 10,
                requireContractAssetCount: 12,
                id: 'customer-123',
                customerNbr: asset.customerNbr,
                customerName: 'Samantha Morris',
                contactPerson: 'John Doe',
                primaryEmail: 'john.doe@ncr.com',
                primaryPhone: '+1-555-123-4567',
                hqAddressline1: '123 Elm Street',
                hqAddressline2: 'Suite 400',
                hqState: 'California',
                hqCity: 'San Francisco',
                hqPostalcode: '94103',
                hqCountry: 'USA',
                createdBy: 'admin',
                updatedBy: 'user-789',
                siteCount: 3,
                assetCount: 150,
                contractCount: 25,
              };

              return {
                key: asset.serialNbr,
                id: asset.serialNbr,
                assetType: asset.assetType,
                itemInstance: asset.itemInstance,
                productId: asset.productId,
                serialNbr: asset.serialNbr,
                softwareKeyId: asset.softwareKeyId,
                addressLine1: asset.address,
                city: asset.city,
                state: asset.state,
                province: asset.province,
                postalCode: asset.postalCode,
                country: asset.country,
                geotag: asset.geotag,
                contracts: asset.contracts,
                status: asset.status,
                createdBy: asset.createdBy,
                updatedBy: asset.updatedBy,
                createdAt: asset.installDate,
                updatedAt: asset.updatedAt,
                active: asset.active,
                isdeleted: asset.isdeleted,
                retired: asset.retired,
                customer,
                services: [service],
                site,
                actionRequired: asset.actionRequired,
                mismatchedContractNos: asset.mismatchedContractNos,
                serviceMismatched: asset.serviceMismatched,
                missingAttributes: asset.missingAttributes,
                locationMismatched: asset.locationMismatched,
                assetClass: asset.assetClass,
                assetServiceStatus: asset.assetServiceStatus,
                endDate: asset.endDate,
                autoUpdated: asset.autoUpdated,
                requireContract: asset.requireContract,
                noAvailableContract: asset.noAvailableContract,
                newLocationScan: asset.newLocationScan,
                scanLatitude: asset.scanLatitude,
                scanLongitude: asset.scanLongitude,
                lastScannedTimeStamp: asset.lastScannedTimeStamp,
              };
            });
          resolve(customerAssets);
        },
        error: (error: Error) => {
          console.error('Error parsing CSV: ', error);
          reject(error);
        },
      });
    });
  } catch (error) {
    console.error('Error parsing CSV: ', error);
    throw error;
  }
};

export default parseCSV;
