import { AssetType } from '../../models/AssetType';
import { ContractType } from '../../models/ContractType';
import { CustomerType } from '../../models/CustomerType';
import { ServiceType } from '../../models/ServiceType';
import { SiteType } from '../../models/SiteType';

const mockCities: string[] = [
  'MIDDLETOWN',
  'MANCHESTER',
  'JACKSONVILLE',
  'FERN PARK',
  'ORLANDO',
  'MANISTIQUE',
  'SAN FRANCISCO',
  'HIGHLAND',
  'CLERMONT',
  'BROOKFIELD',
];

const mockStates: string[] = [
  'NY',
  'CT',
  'FL',
  'FL',
  'FL',
  'MI',
  'CA',
  'IN',
  'FL',
  'WI',
];

const mockSiteNames: string[] = [
  'FLAGSTAR BANK',
  'FAIRWINDS CREDIT UNION',
  'PLAIN CITY',
  'MIDDLEFIELD',
  'AFFINITY FEDERAL CU',
  'NORTH WEST AVE',
  'PYRAMID CREDIT UNION',
  'SF POLICE CREDIT UNION',
  'MACK AVE.',
  'FOUR CORNERS',
];

const mockSiteIds: string[] = [
  '2837-4619-7280',
  '1034-5678-9023',
  '7894-2310-6578',
  '9081-3245-7690',
  '5672-8349-1023',
  '4523-7810-9945',
  '6781-3498-2205',
  '1290-3478-9901',
  '9087-2341-1109',
  '5634-8902-7789',
];

const mockAddresses: string[] = [
  'DALLAS HWY & OLD DALLAS RD',
  'CURTIS 1000 - FAIRWINDS CU',
  '490 JEFFERSON AVE',
  '15200 MADISON RD',
  '141 MOUNT BETHEL RD',
  '1717 N WEST AVE',
  'RITA BRANCH',
  '559 5TH ST',
  '18411 MACK AVE',
  '742 CAGAN VIEW RD',
];

const mockCustomer: CustomerType = {
  autoUpdatedAssetCount: 15,
  requireContractCount: 8,
  updatedAssetCount: 20,
  serviceMismatchAssetCount: 3,
  actionRequiredAssetCount: 5,
  autoMovedAssetCount: 10,
  requireContractAssetCount: 12,
  id: 'customer-123',
  customerNbr: 'cust-45678',
  customerName: 'Samantha Morris',
  contactPerson: 'John Doe',
  primaryEmail: 'john.doe@ncr.com',
  primaryPhone: '+1-555-123-4567',
  hqAddressline1: '123 Elm Street',
  hqAddressline2: 'Suite 400',
  hqState: 'California',
  hqCity: 'San Francisco',
  hqPostalcode: '94103',
  hqCountry: 'USA',
  createdBy: 'admin',
  updatedBy: 'user-789',
  siteCount: 3,
  assetCount: 150,
  contractCount: 25,
};

function generateServiceTag(i: number): string {
  const seed = () => {
    let value = i;
    return () => {
      value = (value * 9301 + 49297) % 233280;
      return value / 233280;
    };
  };

  const random = seed();
  const getRandomChar = (characters: string) =>
    characters.charAt(Math.floor(random() * characters.length));

  const letters = Array.from({ length: 2 }, () =>
    getRandomChar('ABCDEFGHIJKLMNOPQRSTUVWXYZ')
  ).join('');
  const numbers = Array.from({ length: 4 }, () =>
    getRandomChar('0123456789')
  ).join('');

  return `${letters}${numbers}`;
}

const getServices = (i: number) => {
  const newServices = {
    id: generateServiceTag(i),
    serviceNbr: `12345`,
    serviceCategory: `service-cat-1`,
    serviceDesc: `example-service`,
    createdBy: `User-${Math.floor(1 + Math.random() * 10)}`,
    createdAt: new Date(
      Date.now() - 1000 * 60 * 60 * 24 * 30 * 1
    ).toLocaleDateString('en-US'),
    updatedAt: new Date().toISOString(),
    active: true,
    serviceStatus: `ACTIVE`,
    autoUpdate: true,
    added: true,
    removed: false,
  };
  return newServices;
};

const mockContract: ContractType = {
  id: `contract-1234`,
  contractNbr: `12345`,
  category: `Test Category`,
  customer: mockCustomer,
  assets: [],
  services: [],
  ncrId: `id-12345`,
  status: `ACTIVE`,
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  createdBy: `User-${Math.floor(1 + Math.random() * 10)}`,
  updatedBy: `User-${Math.floor(1 + Math.random() * 10)}`,
  createdAt: new Date(
    Date.now() - 1000 * 60 * 60 * 24 * 30 * 1
  ).toLocaleDateString('en-US'),
  updatedAt: new Date(
    Date.now() - 1000 * 60 * 60 * 24 * 30 * 1
  ).toLocaleDateString('en-US'),
  active: true,
  autoUpdated: true,
  contractModifier: ``,
};

const getSite = (i: number) => {
  const newSite = {
    locationMismatched: false,
    id: mockSiteIds[i % 8],
    ncrSiteId: `id-12${i}`,
    customerId: `customer-12${i}`,
    name: mockSiteNames[i % 8],
    originalCorp: `org-corp-${i}`,
    branchNo: `branch${i}`,
    siteStatus: i % 2 ? `ACTIVE` : `INACTIVE`,
    sitePulse: false,
    siteDuns: ``,
    addressLine1:
      i % 3 === 0
        ? mockAddresses[i % 8]
        : `${Math.floor(1 + Math.random() * 1000)} MAIN ST APT`,
    addressLine2: null,
    city: mockCities[i % 8],
    state: mockStates[i % 8],
    province: `Province-${i + 1}`,
    postalCode: `PC-${10000 + i}`,
    country: `Country-${i + 1}`,
    geotag: [
      `geo:${(Math.random() * 90).toFixed(6)},${(Math.random() * 180).toFixed(
        6
      )}`,
    ],
    serviceLocationCode: `12345-${i}`,
    siteUsage: ``,
    channelOrgCode: ``,
    sitePrefLang: ``,
    createdAt: new Date(
      Date.now() - 1000 * 60 * 60 * 24 * 30 * i
    ).toLocaleDateString('en-US'),
    updatedAt: new Date().toISOString(),
    lastScannedTimeStamp: new Date().toISOString(),
  };
  return [newSite];
};

export const mockAssets: AssetType[] = Array.from({ length: 100 }, (_, i) => ({
  id: `asset-${i + 1}`,
  assetType: `Type-${i + 1}`,
  itemInstance: `Instance-${i + 1}`,
  productId: `${Math.floor(6000 + Math.random() * 900)}-${Math.floor(
    3000 + Math.random() * 900
  )}-${Math.floor(6000 + Math.random() * 900)}`,
  serialNbr: `${Math.floor(Math.random() * 90 + 10)}-${Math.floor(
    Math.random() * 90000000 + 10
  )}`,
  softwareKeyId: `Key-${Math.random().toString(36).substring(2, 15)}`,
  addressLine1:
    i % 3 === 0
      ? mockAddresses[i % 8]
      : `${Math.floor(1 + Math.random() * 1000)} MAIN ST APT`,
  city: mockCities[i % 8],
  state: mockStates[i % 8],
  province: `Province-${i + 1}`,
  postalCode: `PC-${10000 + i}`,
  country: `Country-${i + 1}`,
  geotag: `geo:${(Math.random() * 90).toFixed(6)},${(
    Math.random() * 180
  ).toFixed(6)}`,
  contracts: [mockContract],
  status: i % 2 === 0 ? 'Active' : 'Inactive',
  createdBy: `User-${Math.floor(1 + Math.random() * 10)}`,
  updatedBy: `User-${Math.floor(1 + Math.random() * 10)}`,
  createdAt: new Date(
    Date.now() - 1000 * 60 * 60 * 24 * 30 * i
  ).toLocaleDateString('en-US'),
  updatedAt: new Date(
    Date.now() - 1000 * 60 * 60 * 24 * 30 * i
  ).toLocaleDateString('en-US'),
  active: i % 3 === 0,
  isdeleted: i % 4 === 0,
  retired: i % 5 === 0,
  customer: mockCustomer,
  services: [getServices(i)],
  site: getSite(i)[0],
  actionRequired: i % 2 === 0,
  mismatchedContractNos: i % 3 === 0 ? [`Mismatch-${i}`] : [],
  serviceMismatched: i % 4 === 0,
  missingAttributes: i % 5 === 0 ? [`Attribute-${i}`] : [],
  locationMismatched: i % 2 === 0,
  assetClass: `Class-${i + 1}`,
  assetServiceStatus: i % 3 === 0 ? 'Good' : 'Needs Service',
  endDate: new Date(
    Date.now() + 1000 * 60 * 60 * 24 * 30 * i
  ).toLocaleDateString('en-US'),
  autoUpdated: i % 2 === 0,
  requireContract: i % 3 === 0,
  noAvailableContract: i % 4 === 0,
  newLocationScan: i % 5 === 0,
  scanLatitude: (Math.random() * 90).toFixed(6),
  scanLongitude: (Math.random() * 180).toFixed(6),
  lastScannedTimeStamp: new Date().toISOString(),
}));
